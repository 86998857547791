<template>
  <p-drawer :is-show-overlay="isShowOverlay" :steps="step" @on-close="smartRouter.exit()">
    <template #step1>
      <div class="place-items-center flex-column full-width">
        <div
          v-for="(group, groupIndex) in structuredAlarms"
          :key="groupIndex"
          class="pb-16 full-width"
        >
          <div class="place-items-start gap-8 my-8 py-4">
            <nuxt-img :src="icon(group.market)" width="24" height="24" />
            <div
              class="t-fg-primary f-body-16-medium"
              v-text="`${group.market} (${group.alarms.length})`"
            />
          </div>

          <div v-for="(alarm, index) in group.alarms" :key="index">
            <div class="alarm-list-item">
              <div class="place-items-center gap-8">
                <p-icon
                  :icon="alarm.direction"
                  size="md"
                  :color="alarm.direction === 'up' ? 'fg-accent' : 'fg-danger'"
                />
                <div class="align-items-start place-items-start flex-column gap-4">
                  <div
                    class="t-fg-primary f-utility-14"
                    v-html="
                      $t('alarm.priceChange', {
                        sign: `${alarm.direction !== 'down' ? '+' : '-'}%`,
                        amount: alarm.amount,
                        accentClass: `${alarm.direction !== 'down' ? 't-fg-accent' : 't-fg-danger'} f-utility-14-medium`,
                      })
                    "
                  />
                  <div
                    class="t-fg-tertiary f-utility-12"
                    v-text="
                      $t('alarm.targetPrice', {
                        triggerPrice: alarm.triggerPrice,
                        currency: selectedMarket(alarm.market)?.pairs?.payment.toUpperCase(),
                      })
                    "
                  />
                </div>
              </div>
              <div class="place-items-center">
                <div
                  class="t-fg-tertiary f-utility-14 px-8"
                  v-text="
                    alarm.alarmType === 'constant' ? $t('alarm.constant') : $t('alarm.onetime')
                  "
                />
                <p-button
                  button-type="ghost"
                  size="sm"
                  class="m-0 px-4"
                  @click="deleteAlarm(alarm)"
                >
                  <p-icon icon="trash-delete" size="md" color="fg-tertiary" />
                </p-button>
              </div>
            </div>
            <p-divider v-if="group.alarms.length - 1 > index" class="my-2 ml-32" />
          </div>
        </div>
        <div v-if="structuredAlarms?.length === 1" class="place-items-center flex-column">
          <p-info-card v-if="!isShowInfoBox" class="my-8 mb-16 alarm-list__info">
            <div v-text="$t('alarm.infoText')" />
            <template #action>
              <p-icon
                class="cursor-pointer"
                icon="close"
                size="md"
                color="fg-secondary"
                @click="closeInfoBox"
              />
            </template>
          </p-info-card>
          <p-info-card v-if="list.length >= 5" class="my-8 mb-16 alarm-list__info" status="warning">
            <div v-text="$t('alarm.maxLengthWarning')" />
          </p-info-card>
          <p-button
            v-if="list.length < 5 && route.query.market"
            class="alarm-list__button"
            size="md"
            status="success"
            button-type="ghost"
            block="true"
            @click="addNewAlarm"
          >
            {{ $t('alarm.addAlarm') }}
            <p-icon icon="plus-solid" size="md" />
          </p-button>
        </div>
      </div>
    </template>
    <template #step2>
      <alarm-setter :is-show-overlay="false" />
    </template>
  </p-drawer>
</template>

<script setup lang="ts">
import type { MarketConfig, TickerList } from '~/models'

defineProps({
  isShowOverlay: {
    type: Boolean,
    default: true,
  },
})

const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()

const isShowInfoBox = ref<boolean>(localStorage.getItem('infoBox') === 'true')

const list = [
  {
    alarmType: 'constant',
    triggerPrice: '1,812,522',
    direction: 'down',
    amount: '10',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '1,733,347.12',
    direction: 'up',
    amount: '46',
    market: 'btc_tl',
  },
  {
    alarmType: 'onetime',
    triggerPrice: '2,300.00.00',
    direction: 'up',
    amount: '83',
    market: 'btc_tl',
  },
]

const deleteAlarm = (item: any) => {
  // todo: delete alarm width item
  return item
}

const addNewAlarm = () => {
  router.push({
    query: { ...route.query, step: 'setAlarm', market: route.query.market },
  })
}

const closeInfoBox = () => {
  localStorage.setItem('infoBox', 'true')
  isShowInfoBox.value = true
}

const marketTitle = computed<string>(() => {
  return route.query.market?.replace('_', '/')?.toUpperCase?.() || ''
})

const restructureAlarmsByMarket = (alarms: any[]) => {
  const grouped = alarms.reduce(
    (acc, alarm) => {
      const market = alarm.market.replace('_', '/').toUpperCase()
      if (!acc[market]) {
        acc[market] = []
      }
      acc[market].push(alarm)
      return acc
    },
    {} as Record<string, any[]>,
  )

  return Object.entries(grouped).map(([market, alarms]) => ({
    market,
    alarms,
  }))
}

const structuredAlarms = restructureAlarmsByMarket(list)

const selectedMarket = (market: string) => {
  if (typeof market === 'string') {
    return mainStore.getMarkets?.[market] || {}
  }
  return {}
}

const selectedCurrency = (market: string) => {
  const marketKey = selectedMarket(market)?.pairs?.market
  return marketKey ? mainStore.getCurrencies?.[marketKey] || {} : {}
}

const icon = (market: string) => {
  const marketName = market.replace('/', '_').toLowerCase()
  return getAsset({ asset: selectedCurrency(marketName)?.icon, type: 'coin' })
}

const tickers = computed((): TickerList => {
  return mainStore.getTickers
})

const markets = computed((): MarketConfig => {
  return mainStore.getAllMarkets
})

const lastPrice = computed(() => {
  return formatNumber({
    num: tickers.value[route.query.market]?.last,
    precision: markets.value[route.query.market]?.precisions?.price,
  })
})

const paymentCurrency = computed(() => {
  return markets.value[route.query.market]?.pairs?.payment?.toUpperCase()
})

const currentIcon = computed(() => {
  return getAsset({ asset: selectedCurrency(route.query.market)?.icon, type: 'coin' })
})

const step = computed(() => {
  return {
    step1: {
      title: $t('alarm.title'),
      url: 'alarmList',
      back: false,
    },
    step2: {
      title: marketTitle.value,
      url: 'setAlarm',
      back: true,
      close: false,
      description: `${lastPrice.value} ${paymentCurrency.value}`,
      headerTitleSlot: {
        icon: currentIcon.value,
      },
    },
  }
})
</script>
